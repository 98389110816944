[data-theme="default"] {
  --color-primary: #304ffe;
  --color-secondary: #fa6400;
  --color-font-primary: #143182;
  --color-font-secondary: #993d00;
  --color-font-major: #242a2e;
  --color-font-minor: #697077;
  --color-font-interactive-normal: #304ffe;
  --color-font-interactive-hover: #143182;
  --color-font-inverted: #ffffff;
  --color-font-success: #10642a;
  --color-font-warning: #805507;
  --color-font-danger: #750e13;
  --color-font-info: #003d73;
  --color-highlight-background: #304ffe;
  --color-highlight-text: #ffffff;
  --color-focus: rgba(48, 79, 254, .5);
  --color-background-primary: #edf2ff;
  --color-background-secondary: #ffede0;
  --color-background-overlay: rgba(36, 42, 46, .8);
  --color-background-major: #f6f7f8;
  --color-background-minor: #ffffff;
  --color-background-casual: #e8eaee;
  --color-background-success: #dafbe4;
  --color-background-warning: #fff5e6;
  --color-background-danger: #fff0f1;
  --color-background-info: #e3f6ff;
  --color-background-digital-admission: #e3f6ff;
  --color-background-educational-insurance: #ffede0;
  --color-background-payment: #dafbe4;
  --color-background-performance-evaluation: #fcf1ff;
  --color-background-points: #fff5e6;
  --color-button-brand-education-text: #ffffff;
  --color-button-brand-education-background: #304ffe;
  --color-button-brand-education-hover: #1d3cc0;
  --color-button-brand-undergraduate-text: #ffffff;
  --color-button-brand-undergraduate-background: #fa6400;
  --color-button-brand-undergraduate-hover: #cc5200;
  --color-button-brand-postgraduate-text: #ffffff;
  --color-button-brand-postgraduate-background: #9624b3;
  --color-button-brand-postgraduate-hover: #711587;
  --color-button-brand-languages-text: #ffffff;
  --color-button-brand-languages-background: #fb4b53;
  --color-button-brand-languages-hover: #da1e28;
  --color-button-brand-school-text: #ffffff;
  --color-button-brand-school-background: #24a148;
  --color-button-brand-school-hover: #198038;
  --color-button-theme-text: #ffffff;
  --color-button-theme-background: #304ffe;
  --color-button-theme-hover: #1d3cc0;
  --color-alpha-black-016: rgba(0, 0, 0, .16);
  --color-alpha-black-024: rgba(0, 0, 0, .24);
  --color-alpha-black-032: rgba(0, 0, 0, .32);
  --color-alpha-coal-080: rgba(36, 42, 46, .8);
  --color-alpha-gray-016: rgba(19, 23, 26, .16);
  --color-alpha-gray-024: rgba(19, 23, 26, .24);
  --color-alpha-gray-032: rgba(19, 23, 26, .32);
  --color-alpha-sugar-080: rgba(255, 255, 255, .8);
  --color-alpha-blue-050: rgba(48, 79, 254, .5);
  --color-neutral-coal: #242a2e;
  --color-neutral-darkest-gray: #373d42;
  --color-neutral-darker-gray: #697077;
  --color-neutral-dark-gray: #868d95;
  --color-neutral-gray: #9fa5ad;
  --color-neutral-light-gray: #b9bfc7;
  --color-neutral-lighter-gray: #e8eaee;
  --color-neutral-lightest-gray: #f6f7f8;
  --color-neutral-sugar: #ffffff;
  --color-support-success-deep: #10642a;
  --color-support-success-pure: #24a148;
  --color-support-success-clear: #dafbe4;
  --color-support-warning-deep: #805507;
  --color-support-warning-pure: #ffb005;
  --color-support-warning-clear: #fff5e6;
  --color-support-danger-deep: #750e13;
  --color-support-danger-pure: #da1e28;
  --color-support-danger-clear: #fff0f1;
  --color-support-info-deep: #003d73;
  --color-support-info-pure: #1191e6;
  --color-support-info-clear: #e3f6ff;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray-100: #d5d9e0;
  --color-gray-200: #b9bfc7;
  --color-gray-300: #9fa5ad;
  --color-gray-400: #868d95;
  --color-gray-500: #697077;
  --color-gray-600: #50565b;
  --color-gray-700: #373d42;
  --color-gray-800: #242a2e;
  --color-gray-900: #13171a;
  --color-gray-050: #e8eaee;
  --color-gray-000: #f6f7f8;
  --color-blue-100: #c9d9ff;
  --color-blue-200: #97b4ff;
  --color-blue-300: #6e95ff;
  --color-blue-400: #406ffc;
  --color-blue-500: #304ffe;
  --color-blue-600: #1d3cc0;
  --color-blue-700: #143182;
  --color-blue-800: #0d2262;
  --color-blue-900: #051243;
  --color-blue-000: #edf2ff;
  --color-orange-100: #ffd1b3;
  --color-orange-200: #ffbc8f;
  --color-orange-300: #ffa366;
  --color-orange-400: #ff7e29;
  --color-orange-500: #fa6400;
  --color-orange-600: #cc5200;
  --color-orange-700: #993d00;
  --color-orange-800: #662900;
  --color-orange-900: #331400;
  --color-orange-000: #ffede0;
  --color-purple-100: #f7d6ff;
  --color-purple-200: #efb0ff;
  --color-purple-300: #e88eff;
  --color-purple-400: #de6efa;
  --color-purple-500: #d63ffc;
  --color-purple-600: #9624b3;
  --color-purple-700: #711587;
  --color-purple-800: #4f0059;
  --color-purple-900: #260b2b;
  --color-purple-000: #fcf1ff;
  --color-red-100: #fcd0d3;
  --color-red-200: #ffa4a9;
  --color-red-300: #ff767c;
  --color-red-400: #fb4b53;
  --color-red-500: #da1e28;
  --color-red-600: #a51920;
  --color-red-700: #750e13;
  --color-red-800: #570408;
  --color-red-900: #2c080a;
  --color-red-000: #fff0f1;
  --color-green-100: #9deeb2;
  --color-green-200: #56d679;
  --color-green-300: #3dbb61;
  --color-green-400: #24a148;
  --color-green-500: #198038;
  --color-green-600: #10642a;
  --color-green-700: #054719;
  --color-green-800: #01330f;
  --color-green-900: #081b09;
  --color-green-000: #dafbe4;
  --color-yellow-100: #ffe1a8;
  --color-yellow-200: #ffd175;
  --color-yellow-300: #ffc043;
  --color-yellow-400: #ffb005;
  --color-yellow-500: #d59206;
  --color-yellow-600: #aa7406;
  --color-yellow-700: #805507;
  --color-yellow-800: #553707;
  --color-yellow-900: #2b1908;
  --color-yellow-000: #fff5e6;
  --color-cyan-100: #b3e6ff;
  --color-cyan-200: #6ccaff;
  --color-cyan-300: #30b0ff;
  --color-cyan-400: #1191e6;
  --color-cyan-500: #0072c3;
  --color-cyan-600: #0058a1;
  --color-cyan-700: #003d73;
  --color-cyan-800: #002b50;
  --color-cyan-900: #07192b;
  --color-cyan-000: #e3f6ff;
  --color-pink-100: #ffcfe1;
  --color-pink-200: #ffa0c2;
  --color-pink-300: #fa75a6;
  --color-pink-400: #ee538b;
  --color-pink-500: #d12765;
  --color-pink-600: #a11950;
  --color-pink-700: #760a3a;
  --color-pink-800: #57002b;
  --color-pink-900: #2a0a16;
  --color-pink-000: #fff0f6;
  --color-violet-100: #e6d6ff;
  --color-violet-200: #d0b0ff;
  --color-violet-300: #bb8eff;
  --color-violet-400: #9d5efb;
  --color-violet-500: #8a3ffc;
  --color-violet-600: #6e32c9;
  --color-violet-700: #4f2196;
  --color-violet-800: #38146b;
  --color-violet-900: #1e1033;
  --color-violet-000: #f7f1ff;
  --color-social-apple-default: #000000;
  --color-social-apple-button-text: #ffffff;
  --color-social-apple-button-background: #000000;
  --color-social-apple-button-hover: #1a1a1a;
  --color-social-facebook-default: #1877f2;
  --color-social-facebook-button-text: #ffffff;
  --color-social-facebook-button-background: #1877f2;
  --color-social-facebook-button-hover: #09489b;
  --color-social-google-default: #4285f4;
  --color-social-google-button-text: #757575;
  --color-social-google-button-background: #ffffff;
  --color-social-google-button-hover: #ebebeb;
  --color-social-instagram-default: #c13584;
  --color-social-instagram-button-text: #ffffff;
  --color-social-instagram-button-background: #c13584;
  --color-social-instagram-button-hover: #701f4d;
  --color-social-linkedin-default: #0a66c1;
  --color-social-linkedin-button-text: #ffffff;
  --color-social-linkedin-button-background: #0a66c1;
  --color-social-linkedin-button-hover: #053361;
  --color-social-slack-default: #4a154b;
  --color-social-slack-button-text: #ffffff;
  --color-social-slack-button-background: #4a154b;
  --color-social-slack-button-hover: #230a24;
  --color-social-twitter-default: #1da1f2;
  --color-social-twitter-button-text: #ffffff;
  --color-social-twitter-button-background: #1da1f2;
  --color-social-twitter-button-hover: #09669f;
  --color-social-whatsapp-default: #25d366;
  --color-social-whatsapp-button-text: #ffffff;
  --color-social-whatsapp-button-background: #25d366;
  --color-social-whatsapp-button-hover: #167e3d;
  --color-social-youtube-default: #ff0000;
  --color-social-youtube-button-text: #ffffff;
  --color-social-youtube-button-background: #ff0000;
  --color-social-youtube-button-hover: #990000;
  --font-family-default: proxima-nova, Arial, Helvetica, sans-serif;
  --font-family-display: var(--font-family-default);
  --font-family-title: var(--font-family-default);
  --font-family-caption: var(--font-family-default);
  --font-family-text: var(--font-family-default);
  --font-family-monospace: Menlo, Monaco, Consolas, monospace;
  --parameter-border-style-default: solid;
  --parameter-font-style-default: normal;
  --parameter-font-weight-default: 400;
  --parameter-font-weight-semi-bold: 600;
  --parameter-font-weight-bold: 700;
  --parameter-font-weight-extra-bold: 800;
  --parameter-font-weight-display: 800;
  --parameter-font-weight-title: 700;
  --parameter-font-weight-caption: 700;
  --parameter-font-weight-text: 400;
  --parameter-font-weight-interactive: 700;
  --parameter-icon-type-default: fal;
  --parameter-icon-type-brand: fab;
  --parameter-icon-type-interactive: far;
  --parameter-icon-type-platform: fas;
  --parameter-icon-type-duotone: fad;
  --parameter-icon-type-logo: zil;
  --parameter-icon-type-sign-brand: zib;
  --parameter-icon-type-sign-campaigns: zic;
  --parameter-icon-type-sign-facilities: zif;
  --parameter-icon-type-sign-payments: zip;
  --parameter-icon-type-sign-stores: zis;
  --parameter-elevation-00: 0 0 0 1px rgba(19, 23, 26, .16);
  --parameter-elevation-up-10: 0 0 1px 0 rgba(19, 23, 26, .32), 0 -4px 8px -4px rgba(19, 23, 26, .24);
  --parameter-elevation-up-20: 0 0 1px 0 rgba(19, 23, 26, .32), 0 -8px 16px -8px rgba(19, 23, 26, .24);
  --parameter-elevation-up-30: 0 0 1px 0 rgba(19, 23, 26, .32), 0 -16px 32px -16px rgba(19, 23, 26, .24);
  --parameter-elevation-down-10: 0 0 1px 0 rgba(19, 23, 26, .32), 0 4px 8px -4px rgba(19, 23, 26, .24);
  --parameter-elevation-down-20: 0 0 1px 0 rgba(19, 23, 26, .32), 0 8px 16px -8px rgba(19, 23, 26, .24);
  --parameter-elevation-down-30: 0 0 1px 0 rgba(19, 23, 26, .32), 0 16px 32px -16px rgba(19, 23, 26, .24);
  --parameter-text-decoration-default: none;
  --parameter-text-decoration-interactive-accessibility: underline;
  --parameter-text-decoration-past: line-through;
  --parameter-text-transform-default: normal;
  --parameter-text-transform-caption: uppercase;
  --parameter-transition-ease: ease-out;
  --parameter-transition-speed: 0.3s;
  --size-border-width-small: 1px;
  --size-border-width-medium: 2px;
  --size-border-width-large: 4px;
  --size-border-radius-none: 0;
  --size-border-radius-small: 2px;
  --size-border-radius-medium: 4px;
  --size-border-radius-large: 8px;
  --size-border-radius-pill: 500px;
  --size-border-radius-circle: 50%;
  --size-font-extra-colossal: calc( var(--size-font-colossal) * 1.2 );
  --size-font-colossal: calc( var(--size-font-extra-giant) * 1.2 );
  --size-font-extra-giant: calc( var(--size-font-giant) * 1.2 );
  --size-font-giant: calc( var(--size-font-extra-jumbo) * 1.2 );
  --size-font-extra-jumbo: calc( var(--size-font-jumbo) * 1.2 );
  --size-font-jumbo: calc( var(--size-font-extra-large) * 1.2 );
  --size-font-extra-large: calc( var(--size-font-large) * 1.2 );
  --size-font-large: calc( var(--size-font-medium) * 1.2 );
  --size-font-medium: 1rem;
  --size-font-small: calc( var(--size-font-medium) / 1.2 );
  --size-font-extra-small: calc( var(--size-font-small) / 1.2 );
  --size-font-micro: calc( var(--size-font-extra-small) / 1.2 );
  --size-icon-small: 8px;
  --size-icon-medium: 16px;
  --size-icon-large: 24px;
  --size-icon-extra-large: 32px;
  --size-icon-jumbo: 40px;
  --size-icon-extra-jumbo: 48px;
  --size-icon-giant: 56px;
  --size-icon-extra-giant: 64px;
  --size-icon-colossal: 72px;
  --size-icon-extra-colossal: 80px;
  --size-icon-proportion: 1.25;
  --size-icon-area-small: calc( var(--size-icon-small) * var(--size-icon-proportion) );
  --size-icon-area-medium: calc( var(--size-icon-medium) * var(--size-icon-proportion) );
  --size-icon-area-large: calc( var(--size-icon-large) * var(--size-icon-proportion) );
  --size-icon-area-extra-large: calc( var(--size-icon-extra-large) * var(--size-icon-proportion) );
  --size-icon-area-jumbo: calc( var(--size-icon-jumbo) * var(--size-icon-proportion) );
  --size-icon-area-extra-jumbo: calc( var(--size-icon-extra-jumbo) * var(--size-icon-proportion) );
  --size-icon-area-giant: calc( var(--size-icon-giant) * var(--size-icon-proportion) );
  --size-icon-area-extra-giant: calc( var(--size-icon-extra-giant) * var(--size-icon-proportion) );
  --size-icon-area-colossal: calc( var(--size-icon-colossal) * var(--size-icon-proportion) );
  --size-icon-area-extra-colossal: calc( var(--size-icon-extra-colossal) * var(--size-icon-proportion) );
  --size-letter-spacing-default: normal;
  --size-letter-spacing-caption: 1px;
  --size-line-height-display: normal;
  --size-line-height-title: normal;
  --size-line-height-caption: 1.25;
  --size-line-height-text: 1.5;
  --size-screen-extra-small: 320px;
  --size-screen-small: 480px;
  --size-screen-medium: 768px;
  --size-screen-large: 992px;
  --size-screen-extra-large: 1200px;
  --size-nano: 1px;
  --size-micro: 2px;
  --size-extra-small: 4px;
  --size-small: 8px;
  --size-medium: 16px;
  --size-large: 24px;
  --size-extra-large: 32px;
  --size-jumbo: 40px;
  --size-extra-jumbo: 48px;
  --size-giant: 56px;
  --size-extra-giant: 64px;
  --size-colossal: 72px;
  --size-extra-colossal: 80px;
  --size-spacing-stack-initial: 0;
  --size-spacing-stack-nano: 1px;
  --size-spacing-stack-micro: 2px;
  --size-spacing-stack-extra-small: 4px;
  --size-spacing-stack-small: 8px;
  --size-spacing-stack-medium: 16px;
  --size-spacing-stack-large: 24px;
  --size-spacing-stack-extra-large: 32px;
  --size-spacing-stack-jumbo: 40px;
  --size-spacing-stack-extra-jumbo: 48px;
  --size-spacing-stack-giant: 56px;
  --size-spacing-stack-extra-giant: 64px;
  --size-spacing-stack-colossal: 72px;
  --size-spacing-stack-extra-colossal: 80px;
  --size-spacing-inline-initial: var(--size-spacing-stack-initial);
  --size-spacing-inline-nano: var(--size-spacing-stack-nano);
  --size-spacing-inline-micro: var(--size-spacing-stack-micro);
  --size-spacing-inline-extra-small: var(--size-spacing-stack-extra-small);
  --size-spacing-inline-small: var(--size-spacing-stack-small);
  --size-spacing-inline-medium: var(--size-spacing-stack-medium);
  --size-spacing-inline-large: var(--size-spacing-stack-large);
  --size-spacing-inline-extra-large: var(--size-spacing-stack-extra-large);
  --size-spacing-inline-jumbo: var(--size-spacing-stack-jumbo);
  --size-spacing-inline-extra-jumbo: var(--size-spacing-stack-extra-jumbo);
  --size-spacing-inline-giant: var(--size-spacing-stack-giant);
  --size-spacing-inline-extra-giant: var(--size-spacing-stack-extra-giant);
  --size-spacing-inline-colossal: var(--size-spacing-stack-colossal);
  --size-spacing-inline-extra-colossal: var(--size-spacing-stack-extra-colossal);
  --size-spacing-inset-nano: var(--size-spacing-stack-nano) var(--size-spacing-inline-nano);
  --size-spacing-inset-micro: var(--size-spacing-stack-micro) var(--size-spacing-inline-micro);
  --size-spacing-inset-extra-small: var(--size-spacing-stack-extra-small) var(--size-spacing-inline-extra-small);
  --size-spacing-inset-small: var(--size-spacing-stack-small) var(--size-spacing-inline-small);
  --size-spacing-inset-medium: var(--size-spacing-stack-medium) var(--size-spacing-inline-medium);
  --size-spacing-inset-large: var(--size-spacing-stack-large) var(--size-spacing-inline-large);
  --size-spacing-inset-extra-large: var(--size-spacing-stack-extra-large) var(--size-spacing-inline-extra-large);
  --size-spacing-inset-jumbo: var(--size-spacing-stack-jumbo) var(--size-spacing-inline-jumbo);
  --size-spacing-squish-nano: var(--size-spacing-stack-initial) var(--size-spacing-inline-nano);
  --size-spacing-squish-micro: var(--size-spacing-stack-nano) var(--size-spacing-inline-micro);
  --size-spacing-squish-extra-small: var(--size-spacing-stack-micro) var(--size-spacing-inline-extra-small);
  --size-spacing-squish-small: var(--size-spacing-stack-extra-small) var(--size-spacing-inline-small);
  --size-spacing-squish-medium: var(--size-spacing-stack-small) var(--size-spacing-inline-medium);
  --size-spacing-squish-large: var(--size-spacing-stack-medium) var(--size-spacing-inline-large);
  --size-spacing-squish-extra-large: var(--size-spacing-stack-medium) var(--size-spacing-inline-extra-large);
  --size-spacing-squish-jumbo: var(--size-spacing-stack-large) var(--size-spacing-inline-jumbo);
  --size-spacing-stretch-nano: var(--size-spacing-stack-nano) var(--size-spacing-inline-initial);
  --size-spacing-stretch-micro: var(--size-spacing-stack-micro) var(--size-spacing-inline-nano);
  --size-spacing-stretch-extra-small: var(--size-spacing-stack-extra-small) var(--size-spacing-inline-micro);
  --size-spacing-stretch-small: var(--size-spacing-stack-small) var(--size-spacing-inline-extra-small);
  --size-spacing-stretch-medium: var(--size-spacing-stack-medium) var(--size-spacing-inline-small);
  --size-spacing-stretch-large: var(--size-spacing-stack-large) var(--size-spacing-inline-medium);
  --size-spacing-stretch-extra-large: var(--size-spacing-stack-extra-large) var(--size-spacing-inline-medium);
  --size-spacing-stretch-jumbo: var(--size-spacing-stack-jumbo) var(--size-spacing-inline-large);
  --size-z-index-dropdown: 1000;
  --size-z-index-sticky: 1020;
  --size-z-index-fixed: 1030;
  --size-z-index-modal-backdrop: 1040;
  --size-z-index-modal: 1050;
  --size-z-index-popover: 1060;
  --size-z-index-tooltip: 1070;
  --space-nano: 1px;
  --space-micro: 2px;
  --space-extra-small: 4px;
  --space-small: 8px;
  --space-medium: 16px;
  --space-large: 24px;
  --space-extra-large: 32px;
  --space-jumbo: 40px;
  --space-extra-jumbo: 48px;
  --space-giant: 56px;
  --space-extra-giant: 64px;
  --space-colossal: 72px;
  --space-extra-colossal: 80px;
}
